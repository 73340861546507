import { useEffect, useState } from "react";
import styled from "styled-components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Cards } from "./Cards";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPopular } from "../reducers/productActions";

function Products() {
  const [numberOfCardPerPage, setNumberOfCardPerPage] = useState(2);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPopular());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      // Update boxWidth based on screen width
      if (window.matchMedia("(max-width: 1280px)").matches) {
        setNumberOfCardPerPage(1);
      } else {
        setNumberOfCardPerPage(2);
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const products = useSelector((state) => state.products.newProducts);

  return (
    <Wrapper>
      <h3 dir="rtl">הכי נמכרים</h3>
      <Splide
        options={{
          perPage: numberOfCardPerPage,
          arrows: true,
          pagination: true,
          drag: "free",
          gap: `6rem`,
        }}
      >
        {products.map((product) => {
          return (
            <SplideSlide key={product.id}>
              <Cards product={product} />
            </SplideSlide>
          );
        })}
      </Splide>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 4rem 0rem;
`;

export default Products;
