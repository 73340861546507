import styled from "styled-components";
import { NavLink } from "react-router-dom";
import React from "react";

export function Category() {
  return (
    <div>
      <Header>
        <Heading1>Tasman Shoes</Heading1>
        <Nav>
          <NavItem to={"/"}>מומלצים</NavItem>
          <NavItem to={"/contacts"}>אודות</NavItem>
          <NavItem to={"/faq"}>שאלות נפוצות</NavItem>
        </Nav>
      </Header>
    </div>
  );
}

const Header = styled.header`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans|Playfair+Display+SC");

  background: white;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Heading1 = styled.h1`
  font: normal 4em "Playfair Display SC", serif;
  text-align: center;
`;

const Nav = styled.nav`
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
`;

const NavItem = styled(NavLink)`
  flex-grow: 1;
  text-align: center;
  padding: 1em;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    transform: scaleX(0);
    background: #333;
    transition: 0.7s transform cubic-bezier(0.06, 0.9, 0.28, 1);
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;
