import React, { useEffect } from "react";
import styled from "styled-components";

function Faq() {
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button");
    const toggleAccordion = (event) => {
      const itemToggle = event.target.getAttribute("aria-expanded");
      for (let i = 0; i < items.length; i++) {
        items[i].setAttribute("aria-expanded", "false");
      }

      if (itemToggle === "false") {
        event.target.setAttribute("aria-expanded", "true");
      }
    };

    items.forEach((item) => item.addEventListener("click", toggleAccordion));

    return () => {
      items.forEach((item) =>
        item.removeEventListener("click", toggleAccordion)
      );
    };
  }, []);

  return (
    <MainDiv className="container">
      <h2 dir="rtl">שאלות נפוצות</h2>
      <Accordion className="accordion">
        <div dir="rtl" className="accordion-item">
          <button dir="rtl" id="accordion-button-1" aria-expanded="false">
            <span dir="rtl">לאן יגיע המוצר? </span>
          </button>
          <div className="accordion-content">
            <p>
              כאמור, המוצר יישלח בדואר שליחים לכל כתובת שתבחרו או לנקודת האיסוף
              אותה בחרתם בעת ההזמנה.
            </p>
          </div>
        </div>
        <div className="accordion-item">
          <button id="accordion-button-2" aria-expanded="false">
            <span dir="rtl">תוך כמה זמן יגיע המוצר?</span>
          </button>
          <div className="accordion-content">
            <p>
              במשלוח – תוך 7 ימי עסקים. השליח יגיע אליכם בשעה שנוחה לכם, בתיאום
              טלפוני מראש.
            </p>
          </div>
        </div>
        <div className="accordion-item">
          <button id="accordion-button-3" aria-expanded="false">
            <span dir="rtl">יתרונות נוספים שכדאי להכיר</span>
          </button>
          <div className="accordion-content">
            <p>
              החזרה חינם – עד 30 יום ממועד הרכישה! אנחנו ב-Tasman-Shoes מאמינים
              במותגים שלנו ובלקוחות שלנו, ולכן מאפשרים להחזיר כל מוצר עד 30 יום
              מיום הרכישה.
            </p>
          </div>
        </div>
        <div className="accordion-item">
          <button id="accordion-button-4" aria-expanded="false">
            <span>הזמנות שבוצעו לאחר השעה 08:00 בבוקר</span>
          </button>
          <div className="accordion-content">
            <p>
              ימי עסקים נחשבים כימי פעילות בימים א’-ה’, שבהם לא חלים חגים
              ומועדים.
            </p>
          </div>
        </div>
        <div className="accordion-item">
          <button id="accordion-button-5" aria-expanded="false">
            <span>?איך עושים את זה</span>
          </button>
          <div className="accordion-content">
            <p>ניתן להזמין ולקבל מידע נוסף דרך טלגרם</p>
            <p dir="ltr">+972-586010154</p>
          </div>
        </div>
      </Accordion>
      <script src="script.js"></script>
    </MainDiv>
  );
}

const Accordion = styled.div`
  .accordion-item {
    border-bottom: 1px solid #e5e5e5;
    direction: rtl;
    padding: 1em 0;
    h2,
    button {
      position: relative;
      display: block;
      text-align: right;
      width: 100%;
      padding: 1em 0;
      color: #7288a2;
      font-size: 1.15rem;
      font-weight: 400;
      border: none;
      background: none;
      outline: none;

      &:hover,
      &:focus {
        cursor: pointer;
        color: #C1271B;
      }

      .accordion-title {
        padding: 1em 1.5em 1em 0;
      }

      .icon {
        display: inline-block;
        position: absolute;
        top: 18px;
        right: 0;
        width: 22px;
        height: 22px;
        border: 1px solid;
        border-radius: 22px;

        &::before {
          display: block;
          position: absolute;
          content: "";
          top: 9px;
          left: 5px;
          width: 10px;
          height: 2px;
          background: currentColor;
        }

        &::after {
          display: block;
          position: absolute;
          content: "";
          top: 5px;
          left: 9px;
          width: 2px;
          height: 10px;
          background: currentColor;
        }
      }

      &[aria-expanded="true"] {
        color: #C1271B;

        .icon::after {
          width: 0;
        }
      }
    }
  }

  .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    text-align: right;
    direction: rtl;

    p {
      font-size: 1rem;
      font-weight: 300;
      margin: 2em 0;
    }
  }

  .accordion-item button[aria-expanded="true"] + .accordion-content {
    opacity: 1;
    max-height: 9em;
    transition: all 200ms linear;
    will-change: opacity, max-height;
  }
`;

const MainDiv = styled.div`
  padding-top: 10vh;
`;

export default Faq;
