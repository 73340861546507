const initState = {
  popularProducts: [],
  newProducts: [],
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS":
      return { ...state, popularProducts: action.payload.popularProducts };
    case "FETCH_POPULAR":
      return { ...state, newProducts: action.payload.allProducts };
    default:
      return { ...state };
  }
};

export default productReducer;
