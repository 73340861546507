import React from "react";
import styled from "styled-components";

export function Cards({ product }) {
  const navigateToTelegram = () => {
    const phoneNumber = "+972586010154";
    const telegramURL = `https://t.me/${phoneNumber}`;
    window.open(telegramURL);
  };

  const { description, images, title } = product;
  return (
    <Wrapper>
      <ProductImage className="product-img">
        <ProductImageImg
          src={`${process.env.PUBLIC_URL}/images/${images.main}.jpg`}
          alt="Product"
        />
      </ProductImage>
      <ProductInfo className="product-info">
        <ProductText className="product-text">
          <h1>{title}</h1>
          <h2>by Tasman Shoes</h2>
          <p>{description}</p>
        </ProductText>
        <ProductPriceBtn className="product-price-btn">
          <button onClick={navigateToTelegram} type="button">
            Interested
          </button>
        </ProductPriceBtn>
      </ProductInfo>
    </Wrapper>
  );
}

const ProductPriceBtn = styled.div`
  height: 103px;
  width: 327px;
  margin-top: 17px;
  padding-top: 10px;
  position: relative;

  span {
    display: inline-block;
    height: 50px;
    font-family: "Suranna", serif;
    font-size: 34px;
  }

  button:hover {
    background-color: #e53621;
  }

  p {
    display: inline-block;
    position: absolute;
    top: -13px;
    height: 50px;
    font-family: "Trocchi", serif;
    margin: 0 0 0 38px;
    font-size: 28px;
    font-weight: lighter;
    color: #474747;
  }

  button {
    float: right;
    display: inline-block;
    height: 50px;
    width: 176px;
    margin: 0 40px 0 16px;
    box-sizing: border-box;
    border: transparent;
    border-radius: 60px;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #ffffff;
    background-color: #fbbc0f;
    cursor: pointer;
    outline: none;
  }
`;

const ProductText = styled.div`
  height: 325px;
  width: 327px;

  h1 {
    margin: 0 0 0 38px;
    padding-top: 52px;
    font-size: 28px;
    color: #474747;
  }

  h2 {
    margin: 0 0 47px 38px;
    font-size: 13px;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: #d2d2d2;
    letter-spacing: 0.2em;
  }

  p {
    height: 125px;
    margin: 0 0 0 38px;
    font-family: "Playfair Display", serif;
    color: #8d8d8d;
    line-height: 1.7em;
    font-size: 18px;
    font-weight: lighter;
    overflow: hidden;
  }
`;

const ProductInfo = styled.div`
  float: left;
  height: 420px;
  width: 327px;
  border-radius: 0 7px 10px 7px;
  background-color: #ffffff;
`;

const ProductImageImg = styled.img`
  border-radius: 7px 0 0 7px;

  @media (max-width: 768px) {
    height: 250px;
    width: auto;
  }
`;

const ProductImage = styled.div`
  float: left;
  height: 420px;
  width: 327px;
  @media (max-width: 768px) {
    padding-top: 20%;
    left: 25%;
  }
`;

const Wrapper = styled.div`
  height: 500px;
  width: 654px;
  margin: 50px auto;
  border-radius: 7px 7px 7px 7px;
  -webkit-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  transform: scale(0.8);

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 60vh;
    align-items: center;
    justify-content: center;
  }
`;
