const getProducts = async () => {
  try {
    const response = await fetch("data/products.json");
    const jsonData = await response.json();
    return jsonData.products;
  } catch (error) {
    console.error("Error fetching or parsing data:", error);
  }
};

const getPopular = async () => {
  try {
    const response = await fetch("data/products2.json");
    const jsonData = await response.json();
    return jsonData.products;
  } catch (error) {
    console.error("Error fetching or parsing data:", error);
  }
};

export const loadProducts = () => async (dispatch) => {
  const products = await getProducts();
  dispatch({
    type: "FETCH_PRODUCTS",
    payload: {
      popularProducts: products,
    },
  });
};

export const loadPopular = () => async (dispatch) => {
  const products = await getPopular();
  dispatch({
    type: "FETCH_POPULAR",
    payload: {
      allProducts: products,
    },
  });
};
