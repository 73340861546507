import Popular from "../components/Popular";
import Products from "../components/Products";

function Home() {
  return (
    <div>
      <Popular />
      <Products />
    </div>
  );
}

export default Home;
