import React from "react";
import Home from "./Home";
import { Contacts } from "./Contacts";
import { Route, Routes } from "react-router-dom";
import Faq from "./Faq";

export function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/faq" element={<Faq />} />
    </Routes>
  );
}
