import styled from "styled-components";

import React from "react";

export function Contacts() {
  return (
    <Section className="grid info">
      <img src="/images/tasmanLogo.png" alt="Tasman Logo" />
      <div className="intro">
        <div dir="rtl">
          <h2>אודותינו</h2>
          <p>
            אולטימטיבי היא Shoes Tasman עם Blundstone נעלי לרכישת ליעד המובני
            שלך - נסו את חוויית הצעת המחיר והשירות בלתי רגילה שלנו.
          </p>
          <p>
            ב-Tasman Shoes, אנו גאים לספק יותר מפשוט עסקה. אנו מציעים התמחות
            באיכות ושירות מעולה בכל שלב של המסע שלך איתנו. חזון שלנו מתמקד
            בשילוב המושלם של יחס תמורה טובה יחד עם שירות מעולה, מה שהופך אותנו
            לבחירה האולטימטיבית עבור חובבי Blundstone.
          </p>
          <p>למה לבחור ב-Tasman Shoes לרכישת נעלי Blundstone? הנה למה:</p>
          <ol>
            <li>
              **מחירים נוחים:** אנו מבינים את חשיבות המחירים התחרותיים. ב-Tasman
              Shoes, תמצא נעלי Blundstone במחירים שהגיוניים לתקציבך, בלעדיים
              מסירוגין באיכות.
            </li>
            <li>
              **שירות יוצא דופן:** התחייבותנו לשביעות רצונך עולה מעבר לרגיל.
              מרגע שאתה מתרשם מאוסף שלנו ועד לשלב האחרון של הרכישה שלך, צוותנו
              המחויב כאן לסייע לך, מבטיח חוויה חלקה ונעימה.
            </li>
            <li>
              **בחירה נרחבת:** Tasman Shoes מציעה מגוון רחב של דגמים של
              Blundstone, הבטיחו שתוכל למצוא את הזוג המושלם המתאים לך לפי צרכים
              והעדפותיך.
            </li>
            <li>
              **הדרכה מומחית:** לא בטוח איזה דגם של Blundstone הוא הנכון עבורך?
              צוות המומחים שלנו כאן לספק הדרכה מומחית ולעזור לך לקבל החלטה
              מושכלת.
            </li>
          </ol>
          <p>
            כשמדובר ב- Blundstone, Tasman Shoes היא לא רק חנות - אנו שותפים
            האמינים שלך לשביעות רצונך בנעליים. הצטרפו לכמות רבה של אנשים אחרים
            שבחרו בנו כמקום המועדף עליהם לרכישת Blundstone, וחווו את ההבחנה של
            Tasman Shoes היום.
          </p>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  align-items: center;
  padding-top: 10vh;
  li {
    list-style-type: none;
  }

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
